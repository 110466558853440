<template lang="html">
  <div class="interItem">
    <!-- <div class="container"> -->
      <component :is="$route.params.selected"/>
    <!-- </div> -->
  </div>
</template>

<script>
import snake from '@/components/snake'
import disturbingSlider from '@/components/distubringSlider'
import artGeneratorV1 from '@/components/artGeneratorV1'
import semanticBot from '@/components/semanticBot'

export default {
  components: {
    snake,
    disturbingSlider,
    artGeneratorV1,
    semanticBot,
  },
  computed: {

  },
  mounted() {
  }
}
</script>

<style>
/* .interItem .container{
  position: absolute;
  top: var(--nav-h);
  padding: 0 var(--container-padding);
} */
.interItem{
  position: relative;
}
</style>
