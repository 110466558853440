<template lang="html">
  <div class="page semantic">
    <div class="container layout-grid">
      <div class="flex fd-col pad-bot-120">
        <div class="row">
          <label class="unseen" for="user-entry-text">Explore</label>
          <input type="text" id="user-entry-text"
          class="user-input"
          placeholder="write a word"
          v-model="userEntry"
          @keyup.enter="getProp(userEntry, 'tags')"/>
        </div>
        <div class="row space-4">
          <button type="button" name="button" @click="getProp(userEntry, 'tags')">tags</button>
          <button type="button" name="button" @click="getProp(userEntry, 'related')">Related</button>
          <button type="button" name="button" @click="getProp(userEntry, 'definition')">Definition</button>
        </div>
        <div class="row space-2">
          <button type="button" name="button" @click="getProp(userEntry, 'role')">Role</button>
          <button type="button" name="button" @click="getProp(userEntry, 'context')">Context</button>
          <button type="button" name="button" @click="getProp(userEntry, 'opposite')">Opposite</button>
        </div>
        <div class="col space-4">
        <ul class="semantic-list fa-cent fj-cent">
          <li
            v-for="(word, wordIndex) in resToArray"
            class="semantic-pastille"
            :class="{'--unknow': !VOCAB[resToArray[wordIndex]] && resToArray.length > 1}"
            @click="loadWord(resToArray[wordIndex], 'tags')">
            {{word}}
          </li>
        </ul>
        <div class="row space-4 teach" :class="{'--hide': !showTeach}">
          <button type="button"name="button" id="teach-button"
          @click="showTeachForm()">Teach me something ?</button>
        </div>
        <div class="semantic-teach space-4 flex fd-col"
        :class="{'--hide': !formTeach}" ref="teachForm">

            <form class="teach-form">
              <div class="space-2">
                <label for="teach-word" class="teach-label">Word</label>
                <input type="text" class="teach-input" id="teach-word"
                v-model="teach.word">
              </div>
              <div class="space-2">
                <label for="teach-tags" class="teach-label">Tags</label>
                <input type="text" class="teach-input" id="teach-tags"
                v-model="teach.tags">
              </div>
              <div class="space-2">
                <label for="teach-related" class="teach-label">Related</label>
                <input type="text" class="teach-input" id="teach-related"
                v-model="teach.related">
              </div>
              <div class="space-2">
                <label for="teach-definition" class="teach-label">Definition</label>
                <input type="text" class="teach-input" id="teach-definition"
                v-model="teach.definition">
              </div>
              <div class="space-2">
                <label for="teach-role" class="teach-label">Role</label>
                <input type="text" class="teach-input" id="teach-role"
                v-model="teach.role">
              </div>
              <div class="space-2">
                <label for="teach-context" class="teach-label">Context</label>
                <input type="text" class="teach-input" id="teach-context"
                v-model="teach.context">
              </div>
              <div class="space-2">
                <label for="teach-opposite" class="teach-label">Opposite</label>
                <input type="text" class="teach-input" id="teach-opposite"
                v-model="teach.opposite">
              </div>
              <div class="space-2 row">
                <button class="space-2" type="button"
                id="save-button"
                @click="save()">
                  Save
                </button>
              </div>
            </form>

        </div>
      </div>
      </div>
      <div class="flex fd-col">
        <aside class="semantic-aside flex fd-col">
          <h2 class="">Searched</h2>
          <ol class="semantic-list">
            <li class="semantic-list-item"
            v-for="(word,wordIndex) in history.searched"
            :class="{'--unknown': !VOCAB[word], '--selected': userEntry == word}"
            @click="loadWord(word, 'tags')">
              {{word}}
            </li>
          </ol>
        </aside>
      </div>
    </div>
  </div>
</template>

<script>
import svgIcon from '@/components/svgIcon.vue'

export default {
  name: "semantic-bot",
  data(){
    return{
      userEntry: '',
      results: 'Results output here.',
      showTeach: true,
      formTeach: false,
      teach: {
        word: "",
        tags: "",
        related: "",
        definition: "",
        role: "",
        context: "",
        opposite: ""
      },
      history: {
        searched: [],
        taught: [],
      },
      words: {
        shoe: {
          context: ["human being", "clothing", "accessories"],
          definition: 'piece of material worn by human being to walk on the ground',
          phyState: "solid",
          tags: [
            "foot",
            "ground",
            "toe",
            "protection",
            "leg",
            "body",
            "stand",
            "walk",
            "run",
          ],
          role: "to protect the feet",
          plural: "shoes",
        },
        shoes: {
          plural_of: "shoe"
        },
      },
    }
  },
  methods: {
    getProp(str, prop){
      if(str.trim() !== ''){
        if(!this.history.searched.includes(str)){
          this.history.searched.push(str)
        }
        if(this.VOCAB[str]) {
          if(this.VOCAB[str][prop]){
            this.results = this.VOCAB[str][prop]
            // this.showTeach = false
          }
          else{
            this.results = `I don't know the ${prop} of "${str}", sorry`
            // this.showTeach = true
          }
        }
        else{
          this.results = `I don't know what "${str}" is, sorry`
          // this.showTeach = true
          // the DOM know what to display in this case 'Teach it to me ?'
        }
      }
    },
    loadWord(str, prop){
      if(str.split(' ').length <=1){
        if(str.trim() !== ''){
          if(!this.history.searched.includes(str)){
            this.history.searched.push(str)
          }
          this.userEntry = str
          if(this.VOCAB[str]){
            this.getProp(str, prop)
          }
          else{
            this.results = `I don't know what "${str}" is, sorry`
            // this.showTeach = true
          }
        }
        else if(str == ''){
          this.results = 'No entry, no results...'
          // this.showTeach = false
        }
      }
      else{
        // this.results = `Nah, you can't do that...`
        // this.showTeach = false
      }
    },
    showTeachForm(){
      if(this.formTeach){
        this.formTeach =false
        setTimeout( ()=> {
          this.$refs.teachForm.classList.add('hidden')
        }, 200)
      }
      else{
        this.$refs.teachForm.classList.remove('hidden')
        setTimeout( ()=> {
          this.formTeach= true
        }, 16)
      }
    },
    save(){
      var obj = {}
      var wordName = ''
      if(this.teach.word && this.teach.word !== ''){
        wordName = this.teach.word.trim()
      }
      if(this.teach.tags && this.teach.tags !== ''){
        obj.tags = this.teach.tags.split(',').map( x => x.trim()).filter(x => x!=='')
      }
      if(this.teach.related && this.teach.related !== ''){
        obj.related = this.teach.related.split(',').map( x => x.trim()).filter(x => x!=='')
      }
      if(this.teach.definition && this.teach.definition !== ''){
        obj.definition = this.teach.definition.split(',').map( x => x.trim()).filter(x => x!=='')
      }
      if(this.teach.role && this.teach.role !== ''){
        obj.role = this.teach.role.split(',').map( x => x.trim()).filter(x => x!=='')
      }
      if(this.teach.context && this.teach.context !== ''){
        obj.context = this.teach.context.split(',').map( x => x.trim()).filter(x => x!=='')
      }
      if(this.teach.opposite && this.teach.opposite !== ''){
        obj.opposite = this.teach.opposite.split(',').map( x => x.trim()).filter(x => x!=='')
      }
      console.log(obj)

      this.$store.dispatch('updateVocab', { word: `${wordName}`, obj : obj })
    },
    resultsToArray(el){
      if(Array.isArray(el)){
        return el
      }
      else{
        return [el]
      }
    },
  },
  computed: {
    resToArray(){
      if(Array.isArray(this.results)){
        return this.results
      }
      else{
        return [this.results]
      }
    },
    VOCAB(){
      return this.$store.getters.vocabFromDb
    }
  },
  beforeCreate(){
    this.$store.dispatch('fetchVocab')
  },
  mounted(){

  }
}
</script>

<style lang="css">
.semantic{
  position: relative;
  width: 100%;
  height: 100%;
}
.layout-grid{
  margin-top: 40px;
  display: grid;
  grid-template-columns: auto 280px;
  grid-gap: 24px;
  padding-bottom: 80px;
}
@media screen and (max-width:768px){
  .semantic .layout-grid{
    grid-template-columns: 1fr;
  }
}
.results{
  background-color: var(--color-prim);
  color: var(--over-prim);
  padding: 24px 40px;
  margin-top: 40px;
  border-radius: 4px;
  width: 100%;
  height: auto;
  min-height: 160px;
  transition: height 1.2s ease;
}
.semantic-pastille{
  display: inline-block;
  cursor: pointer;
  box-shadow: 0 0 0 1px var(--color-prim);
  padding: 8px 16px;
  margin-top: 16px;
  border-radius: 4px;
  transition: background-color .16s linear;
}
.semantic-pastille:hover{
  background-color: var(--color-prim);
  color: var(--over-prim);
}
.semantic-pastille:not(:last-of-type){
  margin-right: 16px;
}
.semantic-pastille.--unknow::after{
  content: '*';
  margin-left: -4px;
  color: var(--);
}
.unseen{
  position: absolute;
  left: -999999999px;
}
.hidden{
  display: none;
}
.user-input{
  font-size: 4rem;
  height: auto;
  min-height: 80px;
  color: var(--over-bg);
  box-shadow: 0 0 0 0 var(--over-bg);
}
.user-input:hover{
  border-bottom: 1px solid var(--over-bg);
}
#teach-button{
  position: unset;
  width: auto;
  font-weight: 500;
  background-color: var(--color-accent);
  color: var(--over-accent);
  outline: none;
  box-shadow: none;
}
#teach-button:hover{
  background-color: var(--color-accent);
  color: var(--over-accent);
  box-shadow: 0 0 0 1px var(--color-accent);
}
.teach{
  transition: transform .12s linear, opacity .12s linear, height .12s linear;
  opacity: 1;
  transform: translateY(0);
}
.teach.--hide{
  opacity: 0;
  transform: translateY(24px);
}
.semantic-aside{
  display: flex;
}
.semantic-aside h2{
  font-size: 1rem;
  border-bottom: 1px dashed var(--over-bg);
  padding-bottom: calc(var(--size-unit) * 1);
}
.semantic-list{
  min-height: 24px;
  transition: height .16s linear;
}
.semantic-list-item:hover{
  background-color: var(--color-prim);
  color: var(--over-prim);
}
.semantic-list-item.--unknown::after{
  content: '*';
  margin-left: -4px;
}
.semantic-list-item{
  display: inline-block;
  margin-bottom: calc(var(--size-unit)* .5);
  padding: calc(var(--size-unit)* .5);
  font-size: 0.9rem;
  cursor: pointer;
  border-radius: 4px;
}
.semantic-list-item:not(:last-of-type){
  margin-right: var(--size-unit);
}
.semantic-list-item.--selected{
  background-color: var(--color-prim);
  color: var(--over-prim);
}
.semantic-teach{
  opacity: 1;
  transition: opacity .16s linear, transform .16s linear, height 2.4s .16s linear;
  /* overflow: hidden; */
}
.semantic-teach.--hide{
  opacity: 0;
  height: 0;
  transform: translateX(-800px);
}
input.teach-input{
  display: inline-block;
  width: 100%;
  height: 16px;
  background-color: var(--bg-stronger-80);
  height: 24px;
  border-radius: 4px;
  font-size: 1rem;
}
.teach-label{
  font-size: 0.9rem;
}
.pad-bot-80 {
  padding-bottom: 80px;
}
.pad-bot-120 {
  padding-bottom: 120px;
}
</style>
