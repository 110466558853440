<template>
  <div class="page snake">
    <div class="container-fix w-400 h-max-cont">
      <input type="text" class="key-input" v-on:keydown.up="setDirection('U')" v-on:keydown.down="setDirection('D')" v-on:keydown.left="setDirection('L')" v-on:keydown.right="setDirection('R')" v-on:keydown.space="runSnake()">
      <div class="info">
        <p>Score {{formatedScore}}</p>
        <p>Eggs {{ snake.eggsGot}}</p>
      </div>
      <div class="grid" :class="{'crossSideOff':!board.crossSide, 'isPaused': game.isPaused}">
        <template>
          <div class="item" v-for="item in board.cols * board.rows" ref="square">
          </div>
        </template>
      </div>
      <div class="text-splash flex fd-col fj-center fa-center" v-if="game.isPaused && !game.isOver">
        <p class="splash-action" v-if="!game.hasStarted" v-on:click.stop="runSnake()">Play</p>
        <p class="splash-info" v-if="game.hasStarted">Paused</p>
        <!-- <p class="splash-info" v-if="game.hasStarted" v-on:click.stop="runSnake()">Hit space to resume</p> -->
        <p class="splash-action" v-if="game.hasStarted" v-on:click.stop="runSnake()">Unpause</p>
        <p class="splash-action" v-if="game.hasStarted" v-on:click.stop="resetGame()">Reset</p>
      </div>
      <div class="text-splash flex fd-col fj-center fa-center" v-if="game.isOver">
        <p class="splash-info">Game Over</p>
        <p class="splash-action" v-on:click.stop="resetGame()">Start Again</p>
      </div>
    </div>
  </div>
  <!-- <audio id="transform" src="../sound/transform.wav"></audio>
  <audio id="death" src="../sound/death.wav"></audio>
  <audio id="egg-appear" src="../sound/egg-appear.wav"></audio>
  <audio id="egg-swallow" src="../sound/egg-swallow.wav"></audio> -->
</template>

<script>

export default {
  name: 'snake',
  data(){
    return{
      board:{
        cols: 16,
        rows: 24,
        crossSide: false,
      },
      resetBoardDims: {
        cols: 16,
        rows: 24
      },
      snake: {
        currentDir: "R",
        square: [
          [5,2],
          [4,2],
          [3,2],
          [2,2],
          [1,2]
        ],
        interval: null,
        score: 0,
        eggsGot: 0,
      },
      game: {
        isPaused: true,
        speed: 400,
        hasStarted: false,
        isOver: false,
      },
      bonus:{
        interval: null,
        type: [
          "egg",
          "extend",
          "lsd",
        ],
        position: [],
      }
    }
  },
  computed: {
    formatedScore(){
      return this.snake.score.toLocaleString('en');
    },
    currentHead(){
      return this.snake.square[0]
    },
    currentTail(){
      return this.snake.square[this.snake.square.length-1]
    },
    nextHead(){
      // return an array
      if( this.snake.currentDir == "R"){
        // condition when close to the edge
        if (this.snake.square[0][0] == this.board.cols){
          if(this.board.crossSide){
            return [1, this.snake.square[0][1] ]
          }
          else{
            this.gameOver()
            return this.snake.square.shift(1)
          }
        }
        else{
          return [ this.snake.square[0][0] + 1, this.snake.square[0][1] ]
        }
      }
      else if( this.snake.currentDir == "L"){
        if( this.snake.square[0][0] == 1){
          if(this.board.crossSide){
            return [ this.board.cols, this.snake.square[0][1] ]
          }
          else{
            this.gameOver()
            return this.snake.square.slice(1)
          }
        }
        else{
          return [ this.snake.square[0][0] - 1, this.snake.square[0][1] ]
        }
      }
      else if( this.snake.currentDir == "U"){
        if( this.snake.square[0][1] == 1){
          if(this.board.crossSide){
            return [ this.snake.square[0][0], this.board.rows]
          }
          else {
            this.gameOver()
            return this.snake.square.slice(1)
          }
        }
        else{
          return [ this.snake.square[0][0], this.snake.square[0][1] - 1]
        }
      }
      else if( this.snake.currentDir == "D"){
        if( this.snake.square[0][1] == this.board.rows){
          if(this.board.crossSide){
            return [ this.snake.square[0][0], 1]
          }
          else{
            this.gameOver()
            return this.snake.square.slice(1)
          }
        }
        else{
          return [ this.snake.square[0][0], this.snake.square[0][1] + 1]
        }
      }
    },
  },
  methods: {
    resetGame(){
      this.snake.square= [ [5,2], [4,2], [3,2], [2,2], [1,2] ]
      this.snake.interval= null
      this.snake.score= 0
      this.snake.eggsGot= 0
      this.snake.currentDir = "R"

      this.game= {
        isPaused: true,
        speed: 400,
        hasStarted: false,
        isOver: false
      }
      this.bonus= {
        interval: null,
        position: [],
      }
      this.board= this.resetBoardDims
      this.unpaintBoard()
      this.drawSnake()
      const keyInput = document.querySelector('.key-input')
      keyInput.focus()
    },
    drawSnake(colorArg){
      for (let square of this.snake.square){
        this.colorSquare(square, colorArg)
      }
    },
    unpaintBoard(){
      for(let i=0; i< this.board.cols * this.board.rows; i++){
        this.colorIndex(i, 'transparent')
      }
    },
    colorSquare(arg, color){
      this.$refs.square[ Math.abs(( (arg[1] * this.board.cols) - (this.board.cols - (arg[0]) ) -1 ) % (this.board.cols * this.board.rows )) ].style.backgroundColor = color
    },
    colorIndex(arg, color){
      this.$refs.square[arg].style.backgroundColor = color
    },
    setDirection: function(arg){
      // sets direction
      if (arg == "L" && this.snake.currentDir == "U"){
        this.snake.currentDir = arg
        return
      }
      else if (arg == "L" && this.snake.currentDir == "D"){
        this.snake.currentDir = arg
        return
      }
      else if (arg == "R" && this.snake.currentDir == "U"){
        this.snake.currentDir = arg
        return
      }
      else if (arg == "R" && this.snake.currentDir == "D"){
        this.snake.currentDir = arg
        return
      }
      else if (arg == "U" && this.snake.currentDir == "L"){
        this.snake.currentDir = arg
        return
      }
      else if (arg == "U" && this.snake.currentDir == "R"){
        this.snake.currentDir = arg
        return
      }
      else if (arg == "D" && this.snake.currentDir == "L"){
        this.snake.currentDir = arg
        return
      }
      else if (arg == "D" && this.snake.currentDir == "R"){
        this.snake.currentDir = arg
        return
      }
    },
    collisionCheck(){
      for (let i = 2; i< this.snake.square.length; i++){
        if(this.snake.square[i].toString() == this.snake.square[0].toString() ){
          console.log("collision happened")
          return true
        }
      }
      if(this.snake.square[0][0] < 1 || this.snake.square[0][1] < 1){
        return true
      }
    },
    collisionEgg(){
      for (let i = 0; i< this.bonus.position.length; i++){
        if(this.bonus.position[i][0] == this.snake.square[0][0] && this.bonus.position[i][1] == this.snake.square[0][1]
      || this.bonus.position[i][0] == this.snake.square[0][0] && this.bonus.position[i][1] == this.snake.square[0][1]
      || this.bonus.position[i][0]== this.snake.square[0][0] && this.bonus.position[i][1] == this.snake.square[0][1]
      || this.bonus.position[i][0] == this.snake.square[0][0] && this.bonus.position[i][1] == this.snake.square[0][1] ){
          const res = this.bonus.position[i]
          return [res, i]
        }
      }
      return false
    },
    getRandom(min, max){ // max included
      return Math.floor(Math.random() * ((max) - (min) + 1)) + (min);
    },
    tailToHead(){
      if (this.collisionEgg() ){
        // console.log("bonus touched")
        let posBonus = this.collisionEgg()
        this.bonus.position.splice(posBonus[1],1)
        this.colorSquare(this.currentHead, "var(--over-bg)")
        this.snake.score = this.snake.score + 500
        this.snake.eggsGot += 1
        // this.playSound('egg-swallow')

        this.snake.square.unshift(this.nextHead)
        this.colorSquare(this.currentHead, "var(--over-bg)")
      }
      else if( this.collisionCheck()){
        // this.playSound('death')
        this.game.isOver = true
        this.gameOver()
      }
      else{
        // if no collision, tail takes the nextHead coordinate place
        this.colorSquare(this.currentTail, "transparent")
        this.snake.square.splice(this.snake.square.length-1,1)
        this.snake.square.unshift(this.nextHead)
        if(!this.game.isOver){
          this.colorSquare(this.currentHead, "var(--over-bg)")
        }
      }

    },
    updateSpeed(){
      if( (this.snake.score/10) % 17 == 0 || (this.snake.score / 10) % 17 == 0){
        clearInterval(this.snake.interval)
        if(this.game.speed > 100){
          if(this.game.speed >600){
            this.game.speed = this.game.speed - 16
          }
          else if(this.game.speed > 400){
            this.game.speed = this.game.speed - 12
          }
          else if(this.game.speed >200){
            this.game.speed = this.game.speed - 8
          }
          else if(this.game.speed >100){
            this.game.speed = this.game.speed - 4
          }
        }
        this.snake.interval = setInterval(this.sequence, this.game.speed)
      }
      return
    },
    eggAppear(){
      if((this.snake.score/10) % 23 == 0 && this.snake.score !== 10 && this.snake.score !== 0){
        this.bonus.position.push([ Math.round(this.getRandom( 1, this.board.cols) ), Math.round(this.getRandom( 1, this.board.rows) ) ])
        // this.playSound('egg-appear')
      }
      this.eggPaint()
    },
    eggPaint(){
      for (let i= 0; i< this.bonus.position.length; i++){
        this.colorSquare(this.bonus.position[i], "var(--color-prim)")
      }
    },
    sequence: function(){
      if(!this.game.isOver){
        this.updateSpeed()
      }
      if(!this.game.isOver){
        this.eggAppear()
      }
      if(!this.game.isOver){
        this.tailToHead()
      }
      if(!this.game.isOver){
        this.snake.score += 10
      }
    },
    gameOver(){
      this.pauseSnake()
      this.game.isOver = true
      this.drawSnake("var(--bg-strong)")
      this.game.isPaused = true
    },
    runSnake(){
      if(this.game.isPaused && !this.game.isOver){
        this.game.isPaused = false
        if(this.game.hasStarted== false){
          this.game.hasStarted = true
        }
  		  this.snake.interval = setInterval(this.sequence, this.game.speed)
      }
      else{
        this.pauseSnake()
        this.game.isPaused = true
      }
    },
    pauseSnake(){
      // clear the snake interval pauses the game
      clearInterval(this.snake.interval)
      this.snake.interval= null
    },
    adaptDimensions(){
      // passing the cols and rows data to css vars
      const root = document.querySelector(':root')
      root.style.setProperty('--nbrOfCols', this.board.cols)
      root.style.setProperty('--nbrOfRows', this.board.rows)
    },
    playSound(id) {
      document.getElementById(id).play();
    }
  },
  mounted(){
    this.adaptDimensions()
    this.drawSnake("var(--over-bg)")
    const keyInputElement = document.querySelector('.key-input')
    keyInputElement.focus()
  },
  updated(){
    if(!this.game.isOver){
      this.adaptDimensions()
      this.unpaintBoard()
      this.eggAppear()
      this.drawSnake("var(--over-bg)")
      const keyInputElement = document.querySelector('.key-input')
      keyInputElement.focus()
    }
  }
}
</script>


<style>

:root{
  --pixel-size: calc(var(--size-unit)*3);
  --nbrOfCols: ;
  --nrbOfRows: ;

}
.snake{
  /* position: relative;
  width: 100%; */
}
.snake-width{
}

.snake .grid{
  display: grid;
  width: 320px;
  /* height: auto; */
  margin: 0 auto;
  grid-template-columns: repeat(var(--nbrOfCols), 1fr);
  grid-template-rows: repeat(var(--nrbOfRows), 1fr);
  outline: 1px solid var(--color-prim-40);
  transition: outline-color var(--lin-norm), color var(--lin-norm), background-color var(--lin-norm);
}
.snake .grid.crossSideOff{
  /* outline: 2px dash var(--color-accent); */
  animation: side-barrier .25s alternate infinite linear;
}
.snake .grid.isPaused{
  animation-play-state: paused;
}
@keyframes side-barrier{
  0%{
    outline: 1px solid var(--color-prim);
  }
  100%{
    outline: 6px solid var(--color-prim);
  }
}
.snake .item{
  min-height: 20px;
  box-shadow: 0 0 0 1px var(--color-prim-40) inset;
  transition: box-shadow var(--lin-norm);
}
.item:not([style="background-color: transparent"]){
}
.key-input{
  width: 100%;
  border: 0;
  opacity: 0;
}
.info{
  font-size: 150%;
  right: 40px;
  top: 0;
  bottom: 0;
  width: 100%;
  height: auto;
  color: var(--over-bg);
  display: flex;
  justify-content: space-between;
}
.info p{
  font-variant-numeric: proportional-nums;
}
.over{
  display: flex;
  position: relative;
}
.reset-btn{
  position: relative;
  z-index: 20;
}
.text-splash{
  position: absolute;
  transform: translate(-50%,-50%);
  top: 50%;
  left: 50%;
  text-align: center;
  background-color: var(--bg-stronger-80);
  padding: 16px;
  border-radius: 6px;
  min-width: 240px;
  box-shadow: 0 0 0 1px var(--color-accent);
  transition: background-color var(--lin-cool), box-shadow var(--lin-cool);
}
.splash-action{
  box-shadow: 0 0 0 1px var(--over-bg);
  padding: 8px 16px;
  border-radius: 4px;
  background-color: var(--bg-stronger-80);
  cursor: pointer;
  transition: background-color var(--lin-cool), box-shadow var(--lin-cool);
}
</style>
