<template lang="html">
  <div class="page dazzle">
    <div class="container flex fj-cent fa-cent fd-col">
      <div class="dazzle-grid">
        <div class="dazzle-grid-item" v-for="(squares, squareKey) in cells * cells" ref="dazzle_grid_items">
          <svgIcon  :name="shapes.slice()[getRandom(0, shapes.length - 1 )]"
          ref="shapes"
          />
        </div>
      </div>
      <div class="panel">
        <form class="panel-form flex fd-col fj-cent fa-cent" action="index.html" method="post">
          <p>Number of cells: {{cells * cells}}</p>
          <input class="ynck-range" type="range" name="" step="1"
          :min="2"
          :max="8"
          v-model="cells">
          <div class="row flex fj-between fa-cent">
            <button type="button" name="button"
            @click="repaint">repaint</button>
            <button type="button" name="button"
            @click="reshape">generate</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import svgIcon from '@/components/svgIcon'
const root = document.querySelector(':root')

export default {
  name: "dazzle1",
  data(){
    return{
      cells: 4,
      shapes: [
        "dazzle-4circles",
        "dazzle-bars",
        "dazzle-big-circle",
        "dazzle-corners",
        "dazzle-cross",
        "dazzle-embeded",
        "dazzle-full-square",
        "dazzle-half-circle",
        "dazzle-losange",
        "dazzle-med-cross",
        "dazzle-propeller",
        "dazzle-pyramid",
        "dazzle-quarter-round",
        "dazzle-quarter-triangle",
        "dazzle-ramp",
        "dazzle-sandtimer",
        "dazzle-solo-circle",
        "dazzle-star-propeller",
        "dazzle-star",
        "dazzle-stripes-1",
      ],
      tones: [
        "var(--color-prim)",
        "var(--color-sec)",
        "var(--color-accent)",
        "var(--over-bg)",
      ]
    }
  },
  methods: {
    getRandom(min,max){
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    repaint(){
      for (const shape of this.$refs.shapes){
        // console.log(shape)
        shape._self.$el.style.fill= this.tones[0,this.getRandom(0,this.tones.length-1)]
      }
    },
    reshape(){
      this.$forceUpdate()
    }
  },
  computed: {

  },
  beforeMount(){
    root.style.setProperty('--dazzle-cells', this.cells)
    root.style.setProperty('dazzle-cell-size', this.cells)
  },
  mounted(){
    this.repaint()
  },
  updated(){
    // console.log("updated!")
    root.style.setProperty('--dazzle-cells', this.cells)
    root.style.setProperty('dazzle-cell-size', this.cells)
    this.repaint()
  }
}
</script>

<style>
:root{
  --dazzle-grid-size: min(60vh, 60vw);
  --dazzle-cells: 6;
  --dazzle-cell-size: calc(var(--dazzle-grid-size)/var(--dazzle-cells));
}
.dazzle{
  position: relative;
  width: 100%;
  height: 100%;
}
.dazzle .panel{
  width: 280px;
  padding: 0 16px;
  margin-top: 16px;
}
.dazzle-grid{
  display: grid;
  grid-template-columns: repeat(var(--dazzle-cells),var(--dazzle-cell-size));
  grid-template-rows: repeat(var(--dazzle-cells),var(--dazzle-cell-size));
  height: var(--dazzle-grid-size);
  width: var(--dazzle-grid-size);
}
.dazzle-grid-item{
  max-width: var(--dazzle-cell-size);
  max-height: var(--dazzle-cell-size);
}
.dazzle svg{
  width: var(--dazzle-cell-size);
  height: var(--dazzle-cell-size);
}
.ynck-range:focus{
  outline: none;
}
.ynck-range{
  margin-bottom: 24px;
}
.ynck-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 12px;
  cursor: pointer;
  border-radius: 2px;
  overflow: hidden;
}
.ynck-range {
  -webkit-appearance: none;
  width: 100%;
  border-radius: 2px;
}
.ynck-range::-webkit-slider-thumb {
   height: 12px;
   width: 45px;
   cursor: pointer;
   -webkit-appearance: none;
   box-shadow: 0 0 0 var(--color-prim), -40px 0 0 var(--color-prim),
   -85px 0 0 var(--color-prim), -130px 0 0 var(--color-prim),
   -175px 0 0 var(--color-prim), -220px 0 0 var(--color-prim),
   -265px 0 0 var(--color-prim), -310px 0 0 var(--color-prim),
   -350px 0 0 var(--color-prim), -390px 0 0 var(--color-prim),
   -409px 0 0 var(--color-prim);
   background: var(--color-accent);
}
button{
  font-family: var(--ff-1);
}
button:active{
  color: var(--color-accent);
  box-shadow: 0 0 0 1px var(--color-accent);
}
</style>
