<template lang="html">
  <div class="disturbing-slider">
    <div class="wrapper" @mousemove="setSliderMouseVars($event)">
      <div class="img-element" :class="{'--selected': imageIndex == imageKey}"
      v-for="(image, imageKey) in images">
        <div class="img-container img-filter-norm" :style="'background-image:url('+image.link+ ');'">
        </div>
        <div class="img-container img-filter-light" :style="'background-image:url('+image.link+ ');'">
        </div>
        <!-- <div class="img-container img-filter-lighter" :style="'background-image:url('+image.link+ ');'">
        </div> -->
      </div>
      <div class="img-info flex fj-center fa-start fd-col" ref="infoPanel" :class="{'--changing': isChanging == true}">
        <h2 class="img-info-title">{{ images[imageIndex].title}}</h2>
        <p class="img-info-desc" v-html="images[imageIndex].desc"></p>
        <p class="img-info-author">Photo by : {{authorName}}</p>
      </div>
      <div class="slider-arrow-cont">
        <div class="slider-arrow-left flex fj-cent fa-cent" @click="imgIndexDec">
          <p>&#10229;</p>
        </div>
        <div class="slider-arrow-right flex fj-cent fa-cent" @click="imgIndexInc">
          <p>&#10230;</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const root = document.querySelector(':root')

export default {
  name: 'disturbing-slider',
  computed: {
    authorName(){
      let authorLink = this.images[this.imageIndex].link
      let nameIndex = authorLink.lastIndexOf("/")
      let nameRest = authorLink.slice(nameIndex+1)
      let authorString = nameRest.replace('-',' ').replace('-','%')
      let name = authorString.slice(0, authorString.indexOf('%')).split(' ').map(x => x.slice(0,1).toUpperCase()+ x.slice(1) ).join(' ')
      return name
    }
  },
  data(){
    return{
      isChanging: false,
      pauseTime: 2000,
      posX: 0,
      posY: 0,
      Xdist1: 0,
      Ydist1: 0,
      Xdist2: 0,
      Xdist2: 0,
      blur1: 0,
      blur2: 0,
      imageIndex: 0,
      images: [
        {
          link: '/img/disturbing-slider/daniel-bowman-R-wqegECgnk-unsplash.jpg',
          title: 'Reflection',
          desc: '“There are three methods to gaining wisdom. The first is reflection, which is the highest. The second is imitation, which is the easiest. The third is experience, which is the bitterest.” - <i>Confucius</i>'
        },
        {
          link: '/img/disturbing-slider/alex-blajan-vNGL14-V5TY-unsplash.jpg',
          title: 'Positivity',
          desc: '“We can complain because rose bushes have thorns, or rejoice because thorns have roses.” - <i>Alphonse Karr</i>'
        },
        {
          link: '/img/disturbing-slider/jason-blackeye-GPPAjJicemU-unsplash.jpg',
          title: 'Beauty',
          desc: '“Sunset is still my favorite color, and rainbow is second”. - <i>Mattie Stepanek</i>'
        },
        {
          link: '/img/disturbing-slider/thomas-ashlock-7G5dkthFyxA-unsplash.jpg',
          title: 'Way of life',
          desc: '“You must live in the present, launch yourself on every wave, find your eternity in each moment.” – <i>Henry David Thoreau</i>'
        },
        {
          link: '/img/disturbing-slider/george-brynzan-NhepANBDBKo-unsplash.jpg',
          title: 'Objectives',
          desc: '“Fix your eyes on perfection and you make almost everything speed towards it.” - <i>William Ellery Channing</i>'
        },
        {
          link: '/img/disturbing-slider/victor-garcia-0NJ9urGXrIg-unsplash.jpg',
          title: 'Environment',
          desc: '“Architecture. Modern. Adaption. To Nature.”'
        },
        {
          link: '/img/disturbing-slider/marc-olivier-jodoin-NqOInJ-ttqM-unsplash.jpg',
          title: 'Fast life',
          desc: '“Large bandwidth, wireless high speed networks has become the arteries of ours cities.” - <i>Anonymous</i>'
        },
        {
          link: '/img/disturbing-slider/matthew-fournier-vTXBKK6l9ZE-unsplash.jpg',
          title: 'Persistence',
          desc: '“What if all the emotions, feelings and memories of a life could be captured and crytalized in a jewel ?” - <i>Anonymous</i>',
        },
        {
          link: '/img/disturbing-slider/travis-yewell-43ScFMWx2xY-unsplash.jpg',
          title: 'Freedom',
          desc: '“Freedom is a concept, a sight of the mind, a state of mind. It can ben re-defined at any moment of the present.“ - <i>Inspired anonymous</i>'
        },
      ]
    }
  },
  methods: {
    addInfoClass(){
      this.isChanging = true
      setTimeout(
        ()=> {
        this.isChanging = false
      }, this.pauseTime)
    },
    imgIndexInc(){
      this.addInfoClass()
      setTimeout(
        () => {
          if(this.imageIndex == this.images.length-1){
            this.imageIndex = 0
          }
          else{
            this.imageIndex ++
          }
        }, this.pauseTime/4
      )
    },
    imgIndexDec(){
      this.addInfoClass()
      setTimeout(
        () => {
          if(this.imageIndex == 0){
            this.imageIndex = this.images.length-1
          }
          else{
            this.imageIndex --
          }
        }, this.pauseTime/4
      )
    },
    setSliderMouseVars($event){
      if($event){
        this.posX = event.clientX
      	this.posY = event.clientY

        this.Xdist1 = Math.round( ( this.posX -(innerWidth/2) ) * 40 / innerHeight )
        this.Ydist1 = Math.round( ( this.posY  -(innerHeight/2)) * 40 / innerWidth )

        // this.blur1 = Math.abs(
        //   Math.round( ( this.posX  - (innerWidth/2)) * 10 / innerWidth)
        // )

        root.style.setProperty('--Xdist1', this.Xdist1+'px')
        root.style.setProperty('--Ydist1', this.Ydist1+'px')
      }
    }
  }
}
</script>

<style>
.disturbing-slider{
  --arrow-box-size: 96px;
}
.--margin{
  width: calc(100% - calc(var(--container-padding)*2));
}
.disturbing-slider .wrapper{
  position: absolute;
  top: var(--nav-h);
  bottom: var(--footer-height);
  left: 0;
  right: 0;
  height: calc((100vh) - calc(var(--footer-height) + var(--nav-h) ));
  width: 100%;
  margin: 0 auto;
  perspective: 1000px;
  overflow: hidden;
}
.disturbing-slider .img-container{
  background-size: cover;
  background-position: center;
  position: absolute;
  width: 100%;
  height: calc((100vh) - calc(var(--footer-height) + var(--nav-h) ));
}
.img-info{
  position: absolute;
  bottom: calc(var(--nav-h)/2);
  margin-left: calc(var(--nav-h)/2);
  margin-right: calc(var(--nav-h)/2);
  width: calc(100% - var(--nav-h));
  height: auto;
  padding: calc(var(--nav-h)/2);
  background-color: var(--bg-strongest-80);
  border-radius: 2px;
  transform: scale(1);
  opacity: 1;
  transition: opacity .8s ease .2s, transform 0.8s ease .2s;
}
.img-info h2,
.img-info p{
  margin: 0;
  margin-bottom: 16px;
}
.img-info p{
  font-size: 0.9rem;
  font-weight: 300;
}
.img-info h2{
  font-size: 1.0rem;
  font-weight: 500;
}
.--changing.img-info{
  transform: scale(0.94);
  opacity: 0;
  transition: opacity .4s ease .12s, transform 0.4s ease .12s;
}
.disturbing-slider .img-element{
  position: absolute;
  width: 100%;
  height: calc((100vh) - calc(var(--footer-height) + var(--nav-h) ));
  opacity: 0;
  transform: scale(0.96);
  transition: transform 0.8s ease, opacity 0.8s ease;
}
.disturbing-slider .img-element.--selected{
  opacity: 1;
  transform: scale(1);
  transition: transform 0.8s ease .8s, opacity 0.8s ease .8s;
}
.disturbing-slider .slider-img{
  width: 100%;
  height: 100%;
  position: absolute;
  height: 100%;
  width: 100%;
}
.slider-arrow-cont{
  position: absolute;
  top: 0;
  bottom: 0;
  height: calc((100vh) - calc(var(--footer-height) + var(--nav-h) ));
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--over-bg);
  pointer-events: none;
}
.slider-arrow-left{
  border-radius: 0 2px 2px 0;
  background-color: var(--bg-strongest-80);
  width: var(--arrow-box-size);
  height: var(--arrow-box-size);
  cursor: pointer;
  margin-left: calc(var(--container-padding)* -1px);
  pointer-events: all;
}
.slider-arrow-right{
  border-radius: 2px 0 0 2px;
  background-color: var(--bg-strongest-80);
  width: var(--arrow-box-size);
  height: var(--arrow-box-size);
  cursor: pointer;
  margin-right: calc(var(--container-padding)* -1px);
  pointer-events: all;
}
.img-filter-lighter{
  opacity: 0;
  filter: none;
  mix-blend-mode: unset;
  transform: translate(var(--Xdist1), var(--Ydist1));
}
.--selected .img-filter-lighter{
  opacity: 0.4;
  mix-blend-mode: color;
}
.img-filter-light{
  opacity: 0;
  filter: none;
  mix-blend-mode: unset;
  transform: translate(var(--Xdist1), var(--Ydist1));
}
.--selected .img-filter-light{
  opacity: 0.5;
  mix-blend-mode: lighten;
}
@media screen and (max-width:800px){
  .disturbing-slider{
    --arrow-box-size: 64px;
  }
  .img-info{
    width: 100%;
    margin: 0;
    bottom: 0;
    padding: calc(var(--nav-h)/3);
    border-radius: 0;
  }
}
</style>
